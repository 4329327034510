import React from 'react'
import "./Hero.css"

const Hero = () => {
  return (
        <div className='hero' id='home'>
            <h1>Digital Analytics <br/>and Transformation</h1>
        </div>
  )
}

export default Hero